<template>
      
  <section class="dashboard">
    
    <router-link class="rounded-button float-right bg-gradient-primary" :to="link('/create')">+</router-link>
    <div class="page-header">
      <h3 class="page-title">
        <span class="page-title-icon bg-gradient-primary text-white mr-2">
          <i class="mdi mdi-check"></i>
        </span> {{ $options.label }}</h3>
        
        <!--
          <nav aria-label="breadcrumb">
            <ul class="breadcrumb">
              <li class="breadcrumb-item active" aria-current="page">
                <span></span>Vista general<i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
              </li>
            </ul>
          </nav>
        -->
    </div>
 
    <div class="row">
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Listando {{ $options.label }}</h4>



    <div class="grid-developments">
            <router-link :to="'/developments/view/'+item.id" class="item-development" v-for="(item, index) in items" :key="index">

          <img class="thumb-development" v-bind:src="thumb(item.featured_image)">

          <div class="development-detail">
          <h5>{{ item.name }}
          </h5>
          <small>
            {{ item.use_type }}
          </small>

          <p>{{ item.description }}</p>
          </div>

          </router-link>

        </div>
          <!--

            <tableGrid :routeName="'developments'">
                <template slot="headers">
                    <th>ID</th>
                    <th>Nombre</th>
                    
                    <th>Acciones</th>
                </template>

                <template v-slot:row="item">


                    <td>{{ item.id }}</td>
                    <td>{{ item.name }}</td>
                    
                    

                  
                </template>
                <template v-slot:start="item">
                      <router-link  class="action-link" :to="link('/detail/'+item.id)"><i class="mdi mdi-chart-bar"></i></router-link>
                </template>


            </tableGrid>-->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import api from '@/util/api.js';
//import tableGrid from '@/components/tableGrid';


export default {
  routeName:'developments',
  label:'Desarrollos',
  name: 'Developments',
  components: {
  //  tableGrid
  },
  data() {
    return {
      items:[],
      listing:false,
     
    };
  },

  computed:{


    isReady(){

      return this.items.length>0;

    }

  },
  methods: {



          

      
  },
  mounted(){


    api.get('developments').then(response=>{

      this.items=response.data;

    });


  }




}



</script>


